<template>
  <v-container>
    <v-row justify="space-between">
      <v-col class="mt-4" cols="8"> </v-col>
      <v-progress-circular
        :width="3"
        color="green"
        :indeterminate="load"
        v-if="showLoad"
        class="mt-5"
      ></v-progress-circular>
      <v-col cols="4" class="mr-n13">
        <v-btn-toggle v-model="timeStatus" color="primary">
          <v-btn text small style="height: 50px" value="week">Weekly</v-btn>
          <v-btn text small style="height: 50px" value="month"> Monthly </v-btn>

          <v-btn text small style="height: 50px" value="quarterly">
            Quarterly
          </v-btn>
          <v-btn text small style="height: 50px" value="year"> 1 Year </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="stats">
        <v-row>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n2
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.total_revenue }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Total Revenue </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Revenue </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n2
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.total_commission }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Total Commission </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Commission </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n2
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.total_unprocessed }}<br />
                    <v-list-item-subtitle>
                      <span class="black--text">Total Unprocessed</span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Amount That is Not Processed Yet </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n2
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.total_payouts }}<br />
                    <v-list-item-subtitle>
                      <span class="black--text">Total Payouts</span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Amount Paid to the Professionals </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                elevation-1
                mt-n2
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.cp_payments.revenue_growth_rate }}%<br />
                    <v-list-item-subtitle>
                      <span class="black--text">Growth Rate </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Growth Rate </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n2
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.average_revenue_per_meeting }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Avg Revenue per Meeting </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Average Revenue Per Meetings </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.average_revenue_per_customer }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Avg Revenue per Customer </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Average Revenue Earned by Customer </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.average_revenue_per_professional }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text"
                        >Avg Revenue per Professional
                      </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Average Revenue Earned by Professional </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.average_commission_per_meeting }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text"
                        >Avg Commission per Meeting
                      </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Average Commission Per Meeting </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £ {{ stats.cp_payments.average_commission_per_customer }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text"
                        >Avg Commission per Custo.
                      </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Average Commission Per Customer </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    £
                    {{ stats.cp_payments.average_commission_per_professional }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text"
                        >Avg Commission per Profe..
                      </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Average Commission Per Professional </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <!-- <v-col
        cols="6"
      >
        <v-btn-toggle
          v-model="usersStatus"
          color="primary"
        >
          <v-btn
            text
            value="all"
          >
            All
          </v-btn>

          <v-btn
            text
            value="PROFESSIONAL_COMMISSION"
          >
            Commission
          </v-btn>
          <v-btn
            text
            value="WITHDRAWAL_REQUEST"
          >
            Withdrawal Request
          </v-btn>
          <v-btn
            text
            value="WITHDRAWAL"
          >
            Approved Withdrawls
          </v-btn>
          <v-btn
            text
            value="REFUND"
          >
            Refunds
          </v-btn>
        </v-btn-toggle>
      </v-col> -->
      <v-col cols="6">
        <v-btn-toggle v-model="usersStatus" color="primary">
          <v-btn text small value="all" style="height: 50px"> All </v-btn>

          <v-btn
            text
            small
            value="COMMISSION"
            style="height: 50px"
          >
            Commission
          </v-btn>
          <v-btn text small value="WITHDRAWAL_REQUEST" style="height: 50px">
            Withdrawal Request
          </v-btn>
          <v-btn text small value="WITHDRAWAL" style="height: 50px">
            Approved Withdrawal
          </v-btn>
          <v-btn text small value="REFUND" style="height: 50px">
            Refunds
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="6" class="mt-3">
        <v-row>
          <div style="width: 200px">
            <date-filter :init-date="initDate" @dateChange="onDateChange" />
          </div>
          <v-text-field
            label="Search Payouts"
            solo
            append-icon="mdi-magnify"
            @change="search"
            style="width: 203px"
          />
          <v-btn
            color="primary"
            class="ml-4"
            @click="userFormOpen = true"
            style="width: 95px; height: 47px"
          >
            <v-icon small> mdi-plus </v-icon>
            Add New
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="transactions.data"
        :server-items-length="tableOptions.totalItems"
        :options.sync="tableOptions"
        :loading="loading"
        :key="tableKey"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems,
        }"
      >
        <template v-slot:[`item.amount`]="{ item }">
          £ {{ item.amount.toFixed(2) }}
        </template>
        <template v-slot:[`item.booking`]="{ item }">
          <!-- {{ item.Booking }} -->
          <v-btn
            v-if="item.booking"
            text
            small
            color="error"
            @click="showBooking(item.booking)"
          >
            {{ item.booking.substring(item.booking.length - 6) }}
          </v-btn>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getReadableDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ getReadableDate(item.updatedAt) }}
        </template>
      </v-data-table>
    </v-card>
    <booking-modal
      v-if="bookingModalShow"
      :open="bookingModalShow"
      :data="bookingData"
      @close="closeBookingModal"
    />
  </v-container>
</template>
<script>
import { getTransactions, transactionsStats } from "@/services/admin";
import bookingModal from "@/components/bookingModal";
import DateFilter from "@/components/dateFilter";

export default {
  components: { bookingModal, DateFilter },
  data() {
    return {
      userFormOpen: false,
      usersStatus: "all",
      timeStatus: "all",
      load: false,
      showLoad: false,
      stats: null,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "_id",
        },
        {
          text: "Customer",
          align: "start",
          sortable: false,
          value: "customer",
        },
        {
          text: "Driver",
          align: "start",
          sortable: false,
          value: "driver",
        },
        {
          text: "Amount",
          align: "start",
          sortable: false,
          value: "amount",
        },
        {
          text: "Transaction Type",
          align: "start",
          sortable: false,
          value: "action",
        },
        {
          text: "Booking",
          align: "start",
          sortable: false,
          value: "booking",
        },
        {
          text: "Created Date",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Updated Date",
          align: "start",
          sortable: false,
          value: "updatedAt",
        },
      ],
      transactions: {
        data: [],
        actions: [],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: false,
      searchKeyword: "",
      bookingModalShow: false,
      bookingData: null,
      dateFilter: null,
      initDate: [],
      tableKey:1,
    };
  },
  watch: {
    tableOptions: {
      handler() {
        // this.loading = true;
        const _self = this;
        // setTimeout(function () {
        //   _self.getData();
        // }, 500);
      },
      deep: true,
    },
    usersStatus() {
      // this.loading = true;
      this.getData();
    },
    
  },
  async mounted() {
    // const date = new Date();
    // if (!localStorage.getItem("filterDate")) {
    //   this.initDate.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    //   this.initDate.push(
    //     `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    //   );
    // } else {
    //   let datearr = localStorage.getItem("filterDate").split(",");
    //   let date1 = new Date(datearr[0]);
    //   let date2 = new Date(datearr[1]);
    //   this.initDate.push(
    //     `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
    //   );
    //   this.initDate.push(
    //     `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`
    //   );
    // }
    this.getData();
    
  },
  methods: {
    search(keyword) {
      // this.loading = true;
      this.searchKeyword = keyword;
      this.getData();
    },
    userModalClose() {
      this.getData();
    },
    onDateChange(newValue) {
      if (newValue.length < 2) return;
      localStorage.setItem("filterDate", newValue);
      let finaldate = localStorage.getItem("filterDate").split(",");
      this.dateFilter = localStorage.getItem("filterDate")
        ? [new Date(finaldate[0]), new Date(finaldate[1])]
        : newValue;
      this.getData();
    },
    async getData() {
      this.loading = true;
      this.transactions.data= []
      const params = {
        status: this.usersStatus === "all" ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.searchKeyword ? 30 : this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        date: this.searchKeyword ? null : this.dateFilter?.join(","),
      };
      getTransactions(params).then((res) => {
          res.data.transactions.forEach((element) => {
            console.log('=============element==================');
            console.log(element);
            console.log('====================================');
         let row =    {
         _id: element._id.substring(element._id.length - 4),
         customer: `${element.customerId?.firstName || ""}  ${element.customerId?.surName || ""}`,
         driver: `${element.driverId?.firstName || ""}  ${element.driverId?.surName || ""}`,
         amount: element.amount,
         action : element.action,
         booking: element.bookingId,
         createdAt : element.createdAt,
         updatedAt: element.updateAt
      }
        this.transactions.data.push(row);
      });
      this.tableOptions.totalItems = res.data.count;
        this.tableKey +=1
        this.loading = false;
      });
     
    },
   
    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },
    showBooking(booking) {
      console.log('====================================');
      console.log(booking);
      console.log('====================================');
      this.bookingModalShow = true;
      this.bookingData = booking;
    },
    closeBookingModal() {
      this.bookingModalShow = false;
      this.bookingData = null;
    },
  },
};
</script>
