<template>
  <v-row justify="end">
    <v-dialog
      v-model="open"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Booking Information</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Property
                      </th>
                      <th class="text-left">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ID</td>
                      <td> {{ data._id ? data._id.substring(data._id.length - 4) : data._id }} </td>
                    </tr>
                    <tr>
                      <td>Driver</td>
                      <td> {{ bookingData.driver }} </td>
                    </tr>
                    <tr>
                      <td>Customer</td>
                      <td> {{ bookingData.customer }} </td>
                    </tr>
                    <tr>
                      <td>Pickup Address</td>
                      <td> {{ data.pickup.address }} </td>
                    </tr>
                    <tr>
                      <td>Dropoff Address</td>
                      <td> {{ data.dropoff.address }} </td>
                    </tr>
                    <tr>
                      <td>Pin</td>
                      <td> {{ data.pin }} </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        <v-chip
                          small
                        >
                          {{ data.status }}
                        </v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Requested At</td>
                      <td> {{ getReadableDate(data.createdAt) }} </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('close')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { getBooking,getCouponByCode } from '@/services/admin'
  export default {
    name: 'BookingModal',
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      data: {
        type: null,
        required: true,
      },
    },
    data () {
      return {
        bookingData: null,
      }
    },
    mounted () {
      getBooking(this.data).then( async res => {
        
        // eslint-disable-next-line no-unused-vars
        const result = res.data[0]
        this.data = result;
        this.bookingData = {
          driver: `${result.driverId.firstName} ${result.driverId.surName}`,
          customer: `${result.customer.firstName} ${result.customer.surName}`,
        }
      })
      // console.log('booking data', this.data)
    },
    methods: {
      getReadableDate (date) {
        const d = new Date(date)
        return d.toUTCString()
      },
       async getCouponCodeDetail(code,bookingFee) {

        if(!code){return "No Coupon Used"}
        const res = await getCouponByCode(code);
        const result = res.data.message;
        const price = (result.discount_type === "fixed" ? parseFloat(bookingFee)+parseFloat(result.discount_amount) : parseFloat(bookingFee)+parseFloat(result.discount_amount)/100 );
        const discount = price - (result.discount_type === "fixed" ?
                        Math.max(0, price - result.discount_amount) :
                        price - (price * result.discount_amount / 100))
         return `${code} -- £ ${discount.toFixed(2)}`

      },
    },
  }
</script>
